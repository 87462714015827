// Libraries
import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

// Styles
import styles from './styles.module.scss';

const MediaPage = ({ mediaUrl, mediaTitle, mediaType }) => (
  <>
    {mediaTitle && <h3 className={styles.mediaTitle}>{mediaTitle}</h3>}

    <div className={styles.playerWrapper}>
      {mediaType === 'tour_3d' || mediaType === 'tour_vr' ? (
        <iframe title="mytourFrame" width="100%" height="100%" src={mediaUrl} />
      ) : (
        <ReactPlayer className={styles.player} url={mediaUrl} width="100%" height="100%" controls />
      )}
    </div>
  </>
);

MediaPage.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
  mediaTitle: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired
};

export default MediaPage;
