// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import MediaPage from 'marketing/components/MediaPage';

// Styles
import styles from './styles.module.css';

export default data => {
  const { pageContext } = data;
  const { mediaUrl, mediaTitle, mediaType } = pageContext.mediaData;
  const { winnersLink } = pageContext.data;

  return (
    <div className="theme-background-primary">
      <LayoutContainer includeOrderTicketsCta includeWinnersLink={winnersLink}>
        <div className={`${styles.layoutContainer} `}>
          <MediaPage mediaUrl={mediaUrl} mediaTitle={mediaTitle} mediaType={mediaType} />
        </div>
      </LayoutContainer>
    </div>
  );
};
